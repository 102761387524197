import * as React from "react"
import {Link} from "gatsby"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const CeremonyPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }
    useEffect(() => {
        Axios.get("/log.php?page=ceremony_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu changeLang={changeLang} lang={lang}/>

            <div id="duogram"></div>

            {lang != "en"&&
                <>
                    <h1>Seremoni og bryllupsfest</h1>
                    <p>
                        Både seremoni, middag og bryllupsfest vil avholdes på <a target="_blank" href="https://g.page/galaziaaktibeach?share">Galazia Akti i Schinias</a>.
                        Galazia Akti er et eventlokale langs Schinias-stranden, en av Athens fineste strender.
                    </p>

                    <h2>Seremoni</h2>
                    <p>Det vil avholdes bryllupsseremoni i forkant av bryllupsfesten. Oppstart kl 20:00 lokal tid.</p>

                    <h2>Middag og bryllupsfest</h2>
                    <p>Etter seremonien og fotografering vil middag og etterfølgende bryllupsfest avholdes et lite steinkast unna seremonistedet.</p>

                    <p>
                        Rundt 3-3:30 vil det serveres nattmat.
                    </p>

                    <p>
                        Bryllupsfesten avsluttes kl 05:00.
                    </p>
                </>
            }
            {lang == "en" &&
                <>
                    <h1>Ceremony and party</h1>
                    <p>
                        The wedding ceremony as well as the wedding party and dinner will take place at <a target="_blank" href="https://g.page/galaziaaktibeach?share">Galazia Akti in Schinias</a>.
                    </p>

                    <h2>Ceremony</h2>
                    <p>The wedding ceremony will be held at the event area. The event will start at 8:00 PM.</p>

                    <h2>Dinner and wedding party</h2>
                    <p>After the ceremony and photo sessions, dinner and the wedding party will be held just a minute walk from the place of the ceremony.</p>

                    <p>
                        At about 3-3:30 a late night snack will be served.
                    </p>

                    <p>
                        The party ends at 5 AM.
                    </p>
                </>
            }

        </main>
    )
}

export default CeremonyPage
